<template>
  <component
    :is="componentName(element, components)"
    v-for="element in page.elements"
    :key="element.id"
    :element="element"
    :class="NARROW_ELEMENTS.includes(element.name) && 'narrow'"
    data-template="rewards-page"
  />
</template>

<script setup lang="ts">
  import { useNotificationStore } from "~/pinia/notifications"
  const { componentName } = useAlchemy()
  const notificationStore = useNotificationStore()
  const authStore = useAuthStore()
  const { api } = useApi()

  const NARROW_ELEMENTS = ["headline", "text_block", "faq"]

  const props = defineProps<{
    page: AlchemyPage
  }>()

  useHead(usePageInfo(props.page))

  onBeforeMount(async () => {
    try {
      const response = await api<any>("/cs/api/session")
      if (response?.user) {
        authStore.setUser(response.user)
      }
    } catch (error) {
      console.log("rewards page onmount session", error)
      authStore.resetState()
    }
  })

  onMounted(() => {
    notificationStore.announce(props.page.title)
  })

  const components = {
    headline: defineAsyncComponent(
      () => import("~/alchemy/elements/headline.vue"),
    ),
    text_block: defineAsyncComponent(
      () => import("~/alchemy/elements/text_block.vue"),
    ),
    faq: defineAsyncComponent(() => import("~/alchemy/elements/faq.vue")),
    rewards_section: defineAsyncComponent(
      () => import("~/alchemy/elements/rewards_section.vue"),
    ),
  }
</script>

<style lang="scss" scoped>
  .narrow {
    max-width: $content-width-small;
    margin: 0 auto;
  }
</style>
